<template>
    <div class="search">
        <div class="search__inner">
            <button class="search__close" @click="close"></button>
            <form @submit.prevent="search" class="search__form">
                <div class="search__form-wrapper">
                    <input 
                        class="search__input" 
                        placeholder="Что вас интересует?"
                        v-model="searchValue">
                    <button class="search__btn" @click="search"></button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    data() {
        return {
            searchValue: ''
        }
    },
    methods: {
        ...mapActions([
            'GET_MORE_PRODUCTS',
            'RESET_PAGE_NUMBER',
            'GET_SEARCH_VALUE'
        ]),
        close(){
            this.$emit('close', false)
        },
        search(){
            this.$emit('close', false)
            this.GET_SEARCH_VALUE(this.searchValue)
            this.RESET_PAGE_NUMBER()
            this.GET_MORE_PRODUCTS()
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .search__inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: #FAF2E4;
        z-index: 100;
    }
    .search__close{
        position: absolute;
        top: 30px;
        right: 60px;
        height: 40px;
        width: 40px;
    }
    .search__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(45deg);
    }
    .search__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(-45deg);
    }
    .search__close:hover{
        cursor: pointer;
    }
    .search__form{
        width: 100%;
    }
    .search__form-wrapper{
        position: relative;
        max-width: 655px;
        margin: 0 auto;
    }
    .search__input{
        background: none;
        outline: none;
        border: none;
        border-bottom: 1px solid #000;
        width: 100%;
        font-size: 16px;
        padding: 10px;
    }
    .search__btn{
        position: absolute;
        top: -5px;
        right: 0;
        height: 40px;
        width: 40px;
        background: url(../images/icons/search-ico.svg) no-repeat;
        background-position: center;
    }
    .search__btn:hover{
        cursor: pointer;
    }
</style>