<template>
    <div class="product">
        <div class="product__inner">
            <div class="product-card product__item">
                <button class="product__close" @click="close"></button>
                <div class="product-card__photos">
                    <div class="product-card__main-img">
                        <img class="product-card__img" :src="'https://dff24.com:3000/' + MAIN_PHOTO">
                        <div 
                            v-if="SELECTED_PRODUCT.url1"
                            class="product-card__sub-photos">

                            <img 
                            v-if="SELECTED_PRODUCT.url"
                            class="product-card__additional" 
                            :src="'https://dff24.com:3000/' + SELECTED_PRODUCT.url"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url)"
                            :class="{selected : SELECTED_PRODUCT.url == MAIN_PHOTO}">
                            <img 
                            v-if="SELECTED_PRODUCT.url1"
                            class="product-card__additional" 
                            :src="'https://dff24.com:3000/' + SELECTED_PRODUCT.url1"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url1)"
                            :class="{selected : SELECTED_PRODUCT.url1 == MAIN_PHOTO}">
                            <img 
                            v-if="SELECTED_PRODUCT.url2"
                            class="product-card__additional" 
                            :src="'https://dff24.com:3000/' + SELECTED_PRODUCT.url2"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url2)"
                            :class="{selected : SELECTED_PRODUCT.url2 == MAIN_PHOTO}">
                            <img 
                            v-if="SELECTED_PRODUCT.url3"
                            class="product-card__additional" 
                            :src="'https://dff24.com:3000/' + SELECTED_PRODUCT.url3"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url3)"
                            :class="{selected : SELECTED_PRODUCT.url3 == MAIN_PHOTO}">
                        </div>
                    </div>
                </div>

                <div class="product-card__info">
                    <h1 class="product-card__name">{{SELECTED_PRODUCT.name}}</h1>
                    <span class="product-card__price">{{SELECTED_PRODUCT.price}} р</span>
                    <div class="product-card_mobile">
                        <div class="product-card__buttons">
                            <span class="product-card__quantity">Кол-во</span>
                            <div class="controls product-card__controls">
                                <div class="controls__quantity">
                                    <button class="controls__decrease controls__btn" @click="quantityDecrease">
                                    </button>
                                             
                                    <output class="controls__output">{{SELECTED_PRODUCT.quantity}}</output>
                    
                                    <button class="controls__increase controls__btn" @click="quantityIncrease">
                                    </button>
                                </div>
                                <button class="controls__cart-btn" @click="addToCart">{{textBtnAddToCart}}</button>
                            </div>
                        </div>
                        <div class="product-card__text">
                            <h3 class="product-card__subheading">Описание</h3>
                            <p class="product-card__description">Состав: {{SELECTED_PRODUCT.structure}}</p>

                            <p class="product-card__description">В случае отсутствия каких-либо цветов (определенного вида или оттенка) либо элементов декора магазин оставляет за собой право осуществить эквивалентную по стоимости замену с сохранением общей концепции букета.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    data() {
        return {
           mainPhoto: '',
           isAddedInCart: false
        }
    },
    computed: {
        ...mapGetters([
            'SELECTED_PRODUCT',
            'MAIN_PHOTO'
        ]),
        textBtnAddToCart(){
            return this.isAddedInCart ? 'Товар в корзине' :'В корзину'
        },
    },
    methods: {
        ...mapActions([
            'ADD_TO_CART',
            'SELECT_MAIN_PHOTO'
        ]),
        close(){
            this.$emit('closePopup', false)
        },
        imgChangeSlide(url){
            this.SELECT_MAIN_PHOTO(url)
        },
        quantityDecrease(){
            if (this.SELECTED_PRODUCT.quantity > 0) {
                this.SELECTED_PRODUCT.quantity--
            }
        },
        quantityIncrease(){
            this.SELECTED_PRODUCT.quantity++
        },
        addToCart(){
            this.isAddedInCart = true
            this.ADD_TO_CART(this.SELECTED_PRODUCT)
        },
    },
    mounted() {
        
    },
}
</script>

<style scoped>
    .product__inner{
        position: fixed;
        display: flex;
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .product__item{
        display: flex;
        position: relative;
        max-width: 1270px;
        width: 100%;
        padding: 30px;
        background-color: #FAF2E4;
        overflow-y: scroll;
    }
    .product-card__photos{
        margin-right: 100px;
    }
    .product-card__main-img{
        max-width: 550px;
        min-width: 390px;
    }
    .product-card__img{
        width: 100%;
        aspect-ratio: 1 / 1;
    }
    .product-card__sub-photos{
        margin-top: 25px;
        display: flex;
    }
    .product-card__additional{
        max-width: 126px;
        aspect-ratio: 1 / 1;
        margin-right: 15px;
        transition: all linear .2s;
    }
    .product-card__additional:last-child{
        margin-right: 0;
    }
    .product-card__additional:hover{
        cursor: pointer;
        opacity: .5;
    }
    .product-card__info{
        padding-top: 30px;
        max-width: 510px;
    }
    .product-card__name{
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 40px;
    }
    .product-card__price{
        display: block;
        font-size: 26px;
        margin-bottom: 45px;
    }
    .product-card__quantity{
        display: block;
        margin-bottom: 20px;
    }
    .product-card__controls{
        display: flex;
        margin-bottom: 50px;
    }
    .product-card__text{
        padding-top: 30px;
        border-top: 1px dashed #000;
    }
    .controls__quantity{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #000;
        max-width: 160px;
        height: 55px;
        width: 100%;
    }
    .controls__btn{
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 54px;
        transition: all linear .2s;
    }
    .controls__btn:hover{
        cursor: pointer;
    }
    .controls__decrease:hover{
        background-color: #000;
    }
    .controls__decrease::before{
        position: absolute;
        content: "";
        height: 1px;
        width: 18px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        background-color: #000;
    }
    .controls__decrease:hover::before{
        background-color: #fff;
    }
    .controls__increase{
        margin-right: -1px;
    }
    .controls__increase:hover{
        background-color: #000;
    }
    .controls__increase::before{
        position: absolute;
        content: "";
        height: 1px;
        width: 18px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        background-color: #000;
    }
    .controls__increase::after{
        position: absolute;
        content: "";
        height: 1px;
        width: 18px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        transform: rotate(90deg);
        background-color: #000;
    }
    .controls__increase:hover::before,
    .controls__increase:hover::after{
        background-color: #fff;
    }
    .controls__output{
        display: inline-block;
        font-size: 24px;
        margin: 0 10px;
    }
    .controls__cart-btn{
        display: inline-block;
        margin-left: 40px;
        font-size: 18px;
        text-transform: uppercase;
        padding: 15px 60px;
        color: #fff;
        background-color: #000;
        transition: all linear .2s;
    }
    .controls__cart-btn:hover{
        cursor: pointer;
        opacity: .8;
    }
    .product-card__subheading{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 25px;
    }
    .product-card__description{
        margin-bottom: 20px;
    }
    .product-card__description:last-child{
        margin-bottom: 0;
    }
    .product__close{
        position: absolute;
        top: 30px;
        right: 30px;
        height: 40px;
        width: 40px;
    }
    .product__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(45deg);
    }
    .product__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(-45deg);
    }
    .product__close:hover{
        cursor: pointer;
    }
    .selected{
        opacity: .5;
        pointer-events: none;
    }
    @media (max-width: 1090px) {
        .product-card__photos{
            margin-right: 25px;
        }
        .controls__cart-btn{
            font-size: 16px;
            text-transform: none;
        }
        .product-card__additional{
            max-width: 75px;
        }
    }
    @media (max-width: 992px){
        .product-card__main-img{
            min-width: 290px;
        }
        .product-card__name{
            font-size: 26px;
        }
        .product-card__controls{
            align-items: center;
        }
        .controls__cart-btn{
            padding: 15px 50px;
            margin-left: 15px;
        }
    }
    @media (max-width: 768px){
        .product__item{
            flex-direction: column;
            overflow-y: scroll;
        }
        .product-card__photos{
            margin-top: 70px;
            margin-right: 0;
        }
        .product-card__main-img{
            max-width: none;
        }
        .product-card__controls{
            align-items: inherit;
        }
    }
    @media (max-width: 600px){
        .product__inner{
            padding: 0;
        }
        .product-card__info{
            padding-top: 25px;
        }
        .product-card__name{
            font-size: 16px;
            margin-bottom: 20px;
        }
        .product-card__price{
            font-size: 24px;
            margin-bottom: 15px;
        }
        .product-card__additional{
            margin-right: 10px;
        }
        .product-card_mobile{
            display: flex;
            flex-direction: column-reverse;
        }
        .product-card__text{
            padding-top: 25px;
            margin-bottom: 25px;
        }
        .product-card__subheading{
            margin-bottom: 15px;
        }
        .product-card__controls{
            flex-direction: column;
        }
        .product__item{
            padding: 15px;
            padding-bottom: 45px;
        }
        .controls__quantity{
            margin-bottom: 30px;
        }
        .controls__cart-btn{
            margin: 0;
            width: 100%;
        }
        .product__close{
            right: 15px;
        }
    }
</style>