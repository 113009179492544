<template>
    <div class="catalog-link">
        <router-link class="catalog-link__link" to="/catalog">
            <div class="catalog-link__inner">
                <p class="catalog-link__text">Перейти в каталог</p>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .catalog-link__inner{
        display: flex;
        justify-content: center;
        padding: 50px 0px;
        border-top: 1px dashed #000;
        border-bottom: 1px dashed #000;
    }
    .catalog-link__link{
        display: block;
        text-decoration: none;
        color: #000;
    }
    .catalog-link__link:active{
        background-color: #DFD9C4;
        /* background-color: #ECD9DA; */
    }
    .catalog-link__text{
        position: relative;
        font-size: 30px;
        text-transform: uppercase;
    }
    .catalog-link__text::after{
        position: absolute;
        content: "";
        top: 32%;
        right: -35px;
        height: 15px;
        width: 15px;
        border-right: 2px solid #000;
        border-top: 2px solid #000;
        transform: rotate(45deg);
    }
    @media (max-width: 600px) {
        .catalog-link__text{
            font-size: 22px;
        }
        .catalog-link__inner{
            padding: 30px 0;
        }
        .catalog-link__text::after{
            top: 26%;
        }
    }
</style>