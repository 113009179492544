<template>
    <div class="popular">
        <div class="catalog-notification">
            <CartNotification :messages="messages"/>
        </div>
        <div class="popular__inner">
            <h1 class="popular__heading">Самые популярные букеты</h1>
            <div class="popular__items">
                <CatalogItem
                    v-for="flower in POPULAR_PRODUCTS"
                    :key="flower.id" 
                    :product_data="flower"
                    @addToCart="addToCart"
                    @showProduct="showProduct"/>
            </div>
            <div :class="{show: !isProductOpened}">
                <ProductPopup
                    @closePopup="closePopup"/>
            </div>
        </div>
    </div>
</template>

<script>
import CatalogItem from '@/components/Catalog-item'
import {mapActions, mapGetters} from 'vuex'
import ProductPopup from '@/components/Product-popup'
import CartNotification from '@/components/Cart-notification';

export default {
    data() {
        return {
            isProductOpened: false,
            messages: []
        }
    },

    components:{
        CatalogItem,
        ProductPopup,
        CartNotification
    },
    computed: {
        ...mapGetters([
            'POPULAR_PRODUCTS'
        ])
    },
    methods: {
        ...mapActions([
            'GET_POPULAR_PRODUCTS'
        ]),
        closePopup(bool){
            this.isProductOpened = bool
            document.body.style.overflowY = 'scroll'
        },
        showProduct(bool){
            this.isProductOpened = bool
            document.body.style.overflowY = 'hidden'
        },
        addToCart(){
            let timeStamp = Date.now().toLocaleString()
            this.messages.unshift(
                {name: "Товар добавлен в корзину", id: timeStamp}
            )
        }
    },
    mounted() {
        this.GET_POPULAR_PRODUCTS()
    },
}
</script>

<style scoped>
    .popular__inner{
        padding-top: 30px;
        border-top: 1px dashed #000;
    }
    .popular__heading{
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .popular__items{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 15px;
    }
    .show{
        display: none;
    }
    @media (max-width: 600px) {
        .popular__inner{
            padding-top: 25px;
        }
        .popular__heading{
            font-size: 26px;
            margin-bottom: 20px;
        }
        .popular__items{
            grid-template-columns: repeat(1, 1fr);
            column-gap: 0;
            row-gap: 40px;
        }
    }
</style>