<template>
    <div class="notification">
        <TransitionGroup name="list" tag="div">
            <p class="notification__message" v-for="message in messages" :key="message.id">{{message.name}}</p>
        </TransitionGroup>
    </div>
</template>

<script>
export default {
    props: {
        messages: {
            type: Array,
            default: ()=>{
                return []
            }
        }
    },
    methods: {
        hideNotification(){
            let vm = this;
            if(vm.messages.length){
                setTimeout(function(){
                    vm.messages.splice(vm.messages.length -1, 1)
                }, 2000)
            }
        }
    },
    watch: {
        messages:{
                handler(){
                    this.hideNotification()
                }, 
                deep: true
            }
    },
    mounted(){
        this.hideNotification()
    }
}
</script>

<style>
    .notification{
        position: fixed;
        top: 120px;
        right: 20px;
        z-index: 9999;
        display: flex;
        flex-direction: column-reverse;
    }
    .notification__message{
        padding: 30px 15px;
        background-color: #EFE7E8;
        border-radius: 5px;
        margin-bottom: 15px;
    }
    .list-enter-active,
    .list-leave-active{
        transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to{
        transform: translateX(120px);
        opacity: 0;
    }
    .list-move{
        transition: transform .2s linear;
    }
    @media (max-width: 600px) {
        .notification__message{
            font-size: 14px;
            padding: 20px 10px;
        }
    }
</style>