<template>
    <div class="banner">
            <div class="banner__inner">
                <div class="banner__content">
                    <h2 class="banner__subheading">принимаем предзаказы на букеты</h2>
                    <h1 class="banner__heading">На доставку цветов</h1>
                    <button class="banner__link" @click="selectCategory">Выбрать букет</button>
                </div>
            </div>
        </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    methods: {
        ...mapActions([
            'SELECT_CATEGORY',
            'GET_MORE_PRODUCTS',
            'RESET_PAGE_NUMBER',
            'RESET_SEARCH_VALUE'
        ]),
        selectCategory(){
            // this.SELECT_CATEGORY('1 СЕНТЯБРЯ');
            // this.RESET_PAGE_NUMBER();
            // this.RESET_SEARCH_VALUE();
            // this.GET_MORE_PRODUCTS();
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .banner__inner{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 700px;
        color: #fff;
        background: url(../images/updated-ban.png) no-repeat;
        background-size: cover;
        padding-right: 15px;
        padding-left: 15px;
    }
    .banner__content{
        width: 100%;
        max-width: 1300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .banner__heading{
        font-size: 186px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 100px;
        font-family: 'Ouvality';
    }
    .banner__subheading{
        font-family: "Tenor Sans", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 53px;
        align-self: flex-end;
    }
    .banner__link{
        font-family: "Tenor Sans", serif;
        font-size: 35px;
        text-transform: uppercase;
        color: #fff;
        line-height: 100%;
        padding: 14px 25px;
        border: 1px solid #fff;
        border-radius: 9px;
        transition: all linear .2s;
    }
    .banner__link:hover{
        background-color: rgba(255, 255, 255, .3);
    }
    @media (max-width: 1240px){
        .banner__heading{
            font-size: 120px;
        }
    }
    @media (max-width: 992px){
        .banner__heading{
            font-size: 90px;
        }
        .banner__subheading{
            font-size: 35px;
        }
        .banner__link{
            font-size: 24px;
        }
    }
    @media (max-width: 600px){
        .banner__inner{
            min-height: auto;
            min-height: 580px;
        }
        .banner__heading{
            font-size: 51px;
            margin-bottom: 50px;
        }
        .banner__subheading{
            font-size: 18px;
            align-self: center;
            padding-left: 20px;
        }
        .banner__link{
            font-size: 18px;
            padding: 8px 15px;
        }
    }
</style>